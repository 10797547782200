import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigName, ConfigService } from '../@vex/services/config.service';
import { MatIconRegistry } from '@angular/material/icon';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icAssigment from '@iconify/icons-ic/twotone-assignment';
import icBaseline from '@iconify/icons-ic/baseline-file-upload';
import icSetting from '@iconify/icons-ic/settings';
import icPeople from '@iconify/icons-ic/people';
import icList from '@iconify/icons-ic/list';

import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vex';

  constructor(private configService: ConfigService,
              private styleService: StyleService,
              private iconRegistry: MatIconRegistry,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService) {
    this.iconRegistry.setDefaultFontSetClass('iconify');
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl')))
    ).subscribe(queryParamMap => {
      this.document.body.dir = 'rtl';
      this.configService.updateConfig({
        rtl: true
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));

    this.navigationService.items = [
      {
        type: 'link',
        label: 'Dashboard',
        route: '/dashboards/analytics',
        icon: icLayers
      },
      {
        type: 'subheading',
        label: 'Facturación online',
        children: [
          {
            type: 'link',
            label: 'Facturas',
            route: '/pages/invoice',
            icon: icAssigment
          },
          
            ]
          },
        
      
     
         
      
      {
        type: 'subheading',
        label: 'Clientes',
        children: [
          {
            type: 'link',
            label: 'Clientes',
            route: '/apps/aio-table',
            icon: icPeople
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Archivo',
        children: [
          {
            type: 'link',
            label: 'Archivo Back Upload',
            route: '/uploadfile/bulkupload',
            icon: icBaseline
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Historial',
        children: [
          {
            type: 'link',
            label: 'Exportar Facturas',
            route: '/invoicehistory/report',
            icon: icList
          },
          {
            type: 'link',
            label: 'Historial de pedidos Web',
            route: '/invoicehistory/orders',
            icon: icList
          },
          {
            type: 'link',
            label: 'Historial de pedidos BackMarket',
            route: '/invoicehistory/backmarketorders',
            icon: icList
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Configuration',
        children: [
          {
            type: 'link',
            label: 'Configuration y logo',
            route: '/configuration/printconfig',
            icon: icSetting
          },
          {
            type: 'link',
            label: 'WebConfig',
            route: '/configuration/webconfig',
            icon: icSetting
          },
        ]
      },
      
    ];
  }
}
