import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './pages/pages/auth/guard';
import { AuthInterceptor } from './services/authintcep/authinterceptor';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UploadfileComponent } from './pages/uploadfile/uploadfile.component';
import {NgxPaginationModule} from 'ngx-pagination';


export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxPaginationModule,
    // Vex
    VexModule
  ],
  providers: [AuthGuard,httpInterceptorProviders,{
    provide: NG_VALUE_ACCESSOR,
    useExisting: UploadfileComponent,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
