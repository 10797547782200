import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { PrintConfiguration } from '../../../models/printconfig';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrintingService {

   private data = new BehaviorSubject<[]>([]);
   isPrinting = false;
  constructor(private http : HttpClient,private router: Router) { }
  savePrintConfig(printconfig : PrintConfiguration) : Observable<any>
  {
    let user = JSON.parse(localStorage.getItem('currentUser'))
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' +  user.token.token
      })}
      return  this.http.post(environment.endPointUri +'/api/printconfig',printconfig, httpOptions );
  }

  getPrintConfig() : Observable<any>
  {
    let user = JSON.parse(localStorage.getItem('currentUser'))
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' +  user.token.token
      })}
      return  this.http.get(environment.endPointUri +'/api/printconfig', httpOptions );
  }

  printAvatar(){
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          @media print {
            .p-page {
              padding: 0 !important;
            }
            .card {
              box-shadow: none !important;
            }
            .page-break { display: block; page-break-before: always; }
            .doNotPrint{display:none !important;}
            .body {-webkit-print-color-adjust: exact;}
          }
          
          /*! Invoice Templates @author: Invoicebus @email: info@invoicebus.com @web: https://invoicebus.com @version: 1.0.0 @updated: 2015-02-27 16:02:57 @license: Invoicebus */
          /* Reset styles */
          @import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,400,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");
          html, body, div, span, applet, object, iframe,
          h1, h2, h3, h4, h5, h6, p, blockquote, pre,
          a, abbr, acronym, address, big, cite, code,
          del, dfn, em, img, ins, kbd, q, s, samp,
          small, strike, strong, sub, sup, tt, var,
          b, u, i, center,
          dl, dt, dd, ol, ul, li,
          fieldset, form, label, legend,
          table, caption, tbody, tfoot, thead, tr, th, td,
          article, aside, canvas, details, embed,
          figure, figcaption, footer, header, hgroup,
          menu, nav, output, ruby, section, summary,
          time, mark, audio, video {
            margin: 0;
            padding: 0;
            border: 0;
            font: inherit;
            font-size: 100%;
            vertical-align: baseline;
          }
          
          html {
            line-height: 1;
          }
          
          ol, ul {
            list-style: none;
          }
          
          table {
            border-collapse: collapse;
            border-spacing: 0;
          }
          
          caption, th, td {
            text-align: left;
            font-weight: normal;
            vertical-align: middle;
          }
          
          q, blockquote {
            quotes: none;
          }
          q:before, q:after, blockquote:before, blockquote:after {
            content: "";
            content: none;
          }
          
          a img {
            border: none;
          }
          
          article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
            display: block;
          }
          
          /* Invoice styles */
          /**
           * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
           * Instead wrap everything in one main <div id="container"> element where you may change
           * something like the font or the background of the invoice
           */
          
          
          /** 
           * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
           * Some browsers may require '!important' in oder to work properly but be careful with it.
           */
          .clearfix {
            padding-top: 5px;
            display: block;
            clear: both;
          }
          
          .hidden {
            display: none;
          }
          
          b, strong, .bold {
            font-weight: bold;
          }
          
          #container {
            font: normal 13px/1.4em 'Open Sans', Sans-serif;
            margin: 0 auto;
            min-height: 1078px;
          }
          
          .invoice-top {
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHJhZGlhbEdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIxMCUiIHN0b3AtY29sb3I9IiMzMzMzMzMiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiLz48L3JhZGlhbEdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
            background: -moz-radial-gradient(center center, circle farthest-corner, #333333 10%, #000000);
            background: -webkit-radial-gradient(center center, circle farthest-corner, #333333 10%, #000000);
            background: radial-gradient(circle farthest-corner at center center, #333333 10%, #000000);
            color: #fff;
            padding: 40px 40px 30px 40px;
          }
          
          .invoice-body {
            padding: 50px 40px 40px 40px;
          }
          
          #memo .logo {
            float: left;
            margin-right: 20px;
          }
          #memo .logo img {
            width: 150px;
            height: 150px;
          }
          #memo .company-info {
            float: right;
            text-align: right;
          }
          #memo .company-info .company-name {
            color: #F8ED31;
            font-weight: bold;
            font-size: 32px;
          }
          #memo .company-info .spacer {
            height: 15px;
            display: block;
          }
          #memo .company-info div {
            font-size: 12px;
            float: right;
            margin: 0 3px 3px 0;
          }
          #memo:after {
            content: '';
            display: block;
            clear: both;
          }
          
          #invoice-info {
            float: left;
            margin-top: 50px;
          }
          #invoice-info > div {
            float: left;
          }
          #invoice-info > div > span {
            display: block;
            min-width: 100px;
            min-height: 18px;
            margin-bottom: 3px;
          }
          #invoice-info > div:last-of-type {
            margin-left: 10px;
            text-align: right;
          }
          #invoice-info:after {
            content: '';
            display: block;
            clear: both;
          }
          
          #client-info {
            float: right;
            margin-top: 50px;
            margin-right: 30px;
            min-width: 220px;
          }
          #client-info > div {
            margin-bottom: 3px;
          }
          #client-info span {
            display: block;
          }
          #client-info > span {
            margin-bottom: 3px;
          }
          
          #invoice-title-number {
            margin-top: 30px;
          }
          #invoice-title-number #title {
            margin-right: 5px;
            text-align: right;
            font-size: 35px;
          }
          #invoice-title-number #number {
            margin-left: 5px;
            text-align: left;
            font-size: 20px;
          }
          
          table {
            table-layout: fixed;
          }
          table th, table td {
            vertical-align: top;
            word-break: keep-all;
            word-wrap: break-word;
          }
          
          #items .first-cell, #items table th:first-child, #items table td:first-child {
            width: 18px;
            text-align: right;
          }
          #items table {
            border-collapse: separate;
            width: 100%;
          }
          #items table th {
            font-weight: bold;
            padding: 12px 10px;
            text-align: right;
            border-bottom: 1px solid #444;
            text-transform: uppercase;
          }
          #items table th:nth-child(2) {
            width: 30%;
            text-align: left;
          }
          #items table th:last-child {
            text-align: right;
          }
          #items table td {
            text-align: right;
          }
          #items table td:first-child {
            text-align: left;
            border-right: none !important;
          }
          #items table td:nth-child(2) {
            text-align: left;
          }
          #items table td:last-child {
            border-right: none !important;
          }
          
          #sums {
            float: right;
            margin-top: 30px;
          }
          #sums table tr th, #sums table tr td {
            min-width: 100px;
            padding: 10px;
            text-align: right;
            font-weight: bold;
            font-size: 14px;
          }
          #sums table tr th {
            text-align: left;
            padding-right: 25px;
            color: #707070;
          }
          #sums table tr td:last-child {
            min-width: 0 !important;
            max-width: 0 !important;
            width: 0 !important;
            padding: 0 !important;
            overflow: visible;
          }
          #sums table tr.amount-total th {
            color: black;
          }
          #sums table tr.amount-total th, #sums table tr.amount-total td {
            font-weight: bold;
          }
          #sums table tr.amount-total td:last-child {
            position: relative;
          }
          #sums table tr.amount-total td:last-child .currency {
            position: absolute;
            top: 3px;
            left: -740px;
            font-weight: normal;
            font-style: italic;
            font-size: 12px;
            color: #707070;
          }
          #sums table tr.amount-total td:last-child:before {
            display: block;
            content: '';
            border-top: 1px solid #444;
            position: absolute;
            top: 0;
            left: -740px;
            right: 0;
          }
          #sums table tr:last-child th, #sums table tr:last-child td {
            color: black;
          }
          
          #terms {
            margin: 100px 0 15px 0;
          }
          #terms > div {
            min-height: 70px;
          }
          
          .payment-info {
            color: #707070;
            font-size: 12px;
          }
          .payment-info div {
            display: inline-block;
            min-width: 10px;
          }
          
          .ib_drop_zone {
            color: #F8ED31 !important;
            border-color: #F8ED31 !important;
          }
          
          /**
           * If the printed invoice is not looking as expected you may tune up
           * the print styles (you can use !important to override styles)
           */
          @media print {
            /* Here goes your print styles */
          }
          
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}
    
    </body>
      </html>`
    );
    popupWin.document.close();
  }
 
  printSage(){
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          @media print {
            .p-page {
              padding: 0 !important;
            }
            .page-break { display: block; page-break-before: always; }
            .card {
              box-shadow: none !important;
            }
            .doNotPrint{display:none !important;}
            .body {-webkit-print-color-adjust: exact;}
          }
          
          @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");
          html, body, div, span, applet, object, iframe,
          h1, h2, h3, h4, h5, h6, p, blockquote, pre,
          a, abbr, acronym, address, big, cite, code,
          del, dfn, em, img, ins, kbd, q, s, samp,
          small, strike, strong, sub, sup, tt, var,
          b, u, i, center,
          dl, dt, dd, ol, ul, li,
          fieldset, form, label, legend,
          table, caption, tbody, tfoot, thead, tr, th, td,
          article, aside, canvas, details, embed,
          figure, figcaption, footer, header, hgroup,
          menu, nav, output, ruby, section, summary,
          time, mark, audio, video {
            margin: 0;
            padding: 0;
            border: 0;
            font: inherit;
            font-size: 100%;
            vertical-align: baseline;
          }
          
          html {
            line-height: 1;
          }
          
          ol, ul {
            list-style: none;
          }
          
          table {
            border-collapse: collapse;
            border-spacing: 0;
          }
          
          caption, th, td {
            text-align: left;
            font-weight: normal;
            vertical-align: middle;
          }
          
          q, blockquote {
            quotes: none;
          }
          q:before, q:after, blockquote:before, blockquote:after {
            content: "";
            content: none;
          }
          
          a img {
            border: none;
          }
          
          article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
            display: block;
          }
          
          /* Invoice styles */
          /**
           * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
           * Instead wrap everything in one main <div id="container"> element where you may change
           * something like the font or the background of the invoice
           */
          
          
          /** 
           * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
           * Some browsers may require '!important' in oder to work properly but be careful with it.
           */
          .clearfix {
            padding-top: 5px;
            display: block;
            clear: both;
          }
          
          .hidden {
            display: none;
          }
          
          b, strong, .bold {
            font-weight: bold;
          }
          
          #container {
            font: normal 13px/1.4em 'Open Sans', Sans-serif;
            margin: 0 auto;
            min-height: 1058px;
          }
          
          #memo .company-name {
            background: #8BA09E url("~/assets/images/arrows.png") 560px center no-repeat;
            background-size: 100px auto;
            padding: 10px 20px;
            position: relative;
            margin-bottom: 15px;
          }
          #memo .company-name span {
            color: white;
            display: inline-block;
            min-width: 20px;
            font-size: 36px;
            font-weight: bold;
            line-height: 1em;
          }
          #memo .company-name .right-arrow {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100px;
            background: url("~/assets/images/right-arrow.png") right center no-repeat;
            background-size: auto 60px;
          }
          #memo .logo {
            float: left;
            clear: left;
            margin-left: 20px;
          }
          #memo .logo img {
            width: 150px;
            height: 100px;
          }
          #memo .company-info {
            margin-left: 20px;
            float: left;
            font-size: 12px;
            color: #8b8b8b;
          }
          #memo .company-info div {
            margin-bottom: 3px;
            min-width: 20px;
          }
          #memo .company-info span {
            display: inline-block;
            min-width: 20px;
          }
          #memo:after {
            content: '';
            display: block;
            clear: both;
          }
          
          #invoice-info {
            float: left;
            margin: 25px 0 0 20px;
          }
          #invoice-info > div {
            float: left;
          }
          #invoice-info > div > span {
            display: block;
            min-width: 20px;
            min-height: 18px;
            margin-bottom: 3px;
          }
          #invoice-info > div:last-child {
            margin-left: 20px;
          }
          #invoice-info:after {
            content: '';
            display: block;
            clear: both;
          }
          
          #client-info {
            float: right;
            margin: 5px 20px 0 0;
            min-width: 220px;
            text-align: right;
          }
          #client-info > div {
            margin-bottom: 3px;
            min-width: 20px;
          }
          #client-info span {
            display: block;
            min-width: 20px;
          }
          
          #invoice-title-number {
            text-align: center;
            margin: 20px 0;
          }
          #invoice-title-number span {
            display: inline-block;
            min-width: 20px;
          }
          #invoice-title-number #title {
            margin-right: 15px;
            text-align: right;
            font-size: 20px;
            font-weight: bold;
          }
          #invoice-title-number #number {
            font-size: 15px;
            text-align: left;
          }
          
          table {
            table-layout: fixed;
          }
          table th, table td {
            vertical-align: top;
            word-break: keep-all;
            word-wrap: break-word;
          }
          
          #items {
            margin: 20px 0 35px 0;
          }
          #items .first-cell, #items table th:first-child, #items table td:first-child {
            width: 18px;
            text-align: right;
          }
          #items table {
            border-collapse: separate;
            width: 100%;
          }
          #items table th {
            padding: 12px 10px;
            text-align: right;
            background: #E6E7E7;
            border-bottom: 4px solid #487774;
          }
          #items table th:nth-child(2) {
            width: 30%;
            text-align: left;
          }
          #items table th:last-child {
            text-align: right;
            padding-right: 20px !important;
          }
          #items table td {
            text-align: right;
          }
          #items table td:first-child {
            text-align: left;
            border-right: 0 !important;
          }
          #items table td:nth-child(2) {
            text-align: left;
          }
          #items table td:last-child {
            border-right: 0 !important;
            padding-right: 20px !important;
          }
          
          .currency {
            border-bottom: 4px solid #487774;
            padding: 0 20px;
          }
          .currency span {
            font-size: 11px;
            font-style: italic;
            color: #8b8b8b;
            display: inline-block;
            min-width: 20px;
          }
          
          #sums {
            float: right;
            background: #8BA09E url("~/assets/images/left-arrow.png") -17px bottom no-repeat;
            background-size: auto 100px;
            color: white;
          }
          #law {
            float: left;
            width: 300px;
            height: 80px;
            background: gainsboro;
            background-size: auto 150px;
            color: black;
          }
          #sums table tr th, #sums table tr td {
            min-width: 100px;
            padding: 8px 20px 8px 35px;
            text-align: right;
            font-weight: 600;
          }
          #sums table tr th {
            text-align: left;
            padding-right: 25px;
          }
          #sums table tr.amount-total th {
            text-transform: uppercase;
          }
          #sums table tr.amount-total th, #sums table tr.amount-total td {
            font-size: 16px;
            font-weight: bold;
          }
          #sums table tr:last-child th {
            text-transform: uppercase;
          }
          #sums table tr:last-child th, #sums table tr:last-child td {
            font-size: 16px;
            font-weight: bold;
            padding-top: 20px !important;
            padding-bottom: 40px !important;
          }
          
          // #terms {
          //   margin: 50px 20px 10px 20px;
          // }
          #terms > span {
            display: inline-block;
            min-width: 20px;
            font-weight: bold;
          }
          #terms > div {
            margin-top: 0px;
            min-height: 0px;
            min-width: 0px;
            font-size: 10px;
          }
          
          // .payment-info {
          //   margin: 0 20px;
          // }
          .payment-info div {
            width: 800px;
            height: 100px;
            font-size: 10px;
            line-height: 1.2;
            color: #8b8b8b;
          }
          
          .ib_bottom_row_commands {
            margin: 10px 0 0 20px !important;
          }
          
          .ibcl_tax_value:before {
            color: white !important;
          }
          
          .box {
            width: 300px;
            height: 100px;
            font-size: 12px;
            border: 1px solid black;
          }
          
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
  printMotoluxzo(){
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
         /*! Invoice Templates @author: Invoicebus @email: info@invoicebus.com @web: https://invoicebus.com @version: 1.0.0 @updated: 2015-02-27 16:02:34 @license: Invoicebus */
/* Reset styles */
@media print {
  .p-page {
    padding: 0 !important;
  }
  .page-break { display: block; page-break-before: always; }
  body {-webkit-print-color-adjust: exact;}
  .doNotPrint{display:none !important;}
  
}
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&subset=cyrillic,cyrillic-ext,latin,greek-ext,greek,latin-ext,vietnamese");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

/* Invoice styles */
/**
 * DON'T override any styles for the <html> and <body> tags, as this may break the layout.
 * Instead wrap everything in one main <div id="container"> element where you may change
 * something like the font or the background of the invoice
 */
 html, body {
  /* MOVE ALONG, NOTHING TO CHANGE HERE! */
}

/** 
 * IMPORTANT NOTICE: DON'T USE '!important' otherwise this may lead to broken print layout.
 * Some browsers may require '!important' in oder to work properly but be careful with it.
 */
.clearfix {
  padding-top: 5px;
  display: block;
  clear: both;
}

.hidden {
  display: none;
}

b, strong, .bold {
  font-weight: bold;
}

#container {
  font: normal 13px/1.4em 'Open Sans', Sans-serif;
  margin: 0 auto;
  min-height: 1158px;
  background: #F7EDEB url('~/assets/images/bg.png') 0 0 no-repeat;
  background-size: 100% auto;
  color: #5B6165;
  position: relative;
}

#memo {
  padding-top: 50px;
  margin: 0 110px 0 60px;
  border-bottom: 1px solid #ddd;
  height: 115px;
}
#memo .logo {
  float: left;
  margin-right: 20px;
}
#memo .logo img {
  width: 150px;
  height: 100px;
}
#memo .company-info {
  float: right;
  text-align: right;
}
#memo .company-info > div:first-child {
  line-height: 1em;
  font-weight: bold;
  font-size: 22px;
  color: #B32C39;
}
#memo .company-info span {
  font-size: 11px;
  display: inline-block;
  min-width: 20px;
}
#memo:after {
  content: '';
  display: block;
  clear: both;
}

#invoice-title-number {
  font-weight: bold;
  margin: 30px 0;
}
#invoice-title-number span {
  line-height: 0.88em;
  display: inline-block;
  min-width: 20px;
}
#invoice-title-number #title {
  text-transform: uppercase;
  padding: 0px 2px 0px 60px;
  font-size: 40px;
  background: #F4846F;
  color: white;
}
#invoice-title-number #number {
  margin-left: 10px;
  font-size: 35px;
  position: relative;
  top: -5px;
}

#client-info {
  float: left;
  margin-left: 60px;
  min-width: 220px;
}
#client-info > div {
  margin-bottom: 3px;
  min-width: 20px;
}
#client-info span {
  display: block;
  min-width: 20px;
}
#client-info > span {
  text-transform: uppercase;
}

table {
  table-layout: fixed;
}
table th, table td {
  vertical-align: top;
  word-break: keep-all;
  word-wrap: break-word;
}

#items {
  margin: 35px 30px 0 30px;
}
#items .first-cell, #items table th:first-child, #items table td:first-child {
  width: 40px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: right;
}
#items table {
  border-collapse: separate;
  width: 100%;
}
#items table th {
  font-weight: bold;
  padding: 5px 8px;
  text-align: right;
  background: #B32C39;
  color: white;
  text-transform: uppercase;
}
#items table th:nth-child(2) {
  width: 30%;
  text-align: left;
}
#items table th:last-child {
  text-align: right;
}
#items table td {
  padding: 2px 1px;
  text-align: right;
  border-bottom: 1px solid #ddd;
}
#items table td:nth-child(2) {
  text-align: left;
}

#sums {
  margin: 25px 30px 0 0;
  background: url("~/assets/images/total-stripe-firebrick.png") right bottom no-repeat;
}
#sums table {
  float: right;
}
#sums table tr th, #sums table tr td {
  min-width: 100px;
  padding: 9px 8px;
  text-align: right;
}
#sums table tr th {
  font-weight: bold;
  text-align: left;
  padding-right: 35px;
}
#sums table tr td.last {
  min-width: 0 !important;
  max-width: 0 !important;
  width: 0 !important;
  padding: 0 !important;
  border: none !important;
}
#sums table tr.amount-total th {
  text-transform: uppercase;
}
#sums table tr.amount-total th, #sums table tr.amount-total td {
  font-size: 15px;
  font-weight: bold;
}
#sums table tr:last-child th {
  text-transform: uppercase;
}
#sums table tr:last-child th, #sums table tr:last-child td {
  font-size: 15px;
  font-weight: bold;
  color: white;
}

#invoice-info {
  float: left;
  margin: 50px 40px 0 60px;
}
#invoice-info > div > span {
  display: inline-block;
  min-width: 20px;
  min-height: 18px;
  margin-bottom: 3px;
}
#invoice-info > div > span:first-child {
  color: black;
}
#invoice-info > div > span:last-child {
  color: #aaa;
}
#invoice-info:after {
  content: '';
  display: block;
  clear: both;
}

#terms {
  float: left;
  margin-top: 50px;
}
#terms .notes {
  min-height: 30px;
  min-width: 50px;
  color: #B32C39;
}
#terms .payment-info div {
  margin-bottom: 3px;
  min-width: 20px;
}

.thank-you {
  margin: 10px 0 30px 0;
  display: inline-block;
  min-width: 20px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 0.88em;
  float: right;
  padding: 0px 30px 0px 2px;
  font-size: 50px;
  background: #F4846F;
  color: white;
}

.ib_bottom_row_commands {
  margin-left: 30px !important;
}

/**
 * If the printed invoice is not looking as expected you may tune up
 * the print styles (you can use !important to override styles)
 */
@media print {
  /* Here goes your print styles */
}

          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }


  AddData(data)
  {
    this.data.next(data);
  }
  GetData() : BehaviorSubject<[]>
  {
    return this.data;
  }

  printDocument(documentName: string, documentData: string[]) {
    this.isPrinting = true;
    this.router.navigate(['/',
      { outlets: {
        'print': ['print', documentName, documentData.join()]
      }}]);
  }

  
}







