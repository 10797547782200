import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../@vex/layout/layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { AuthGuard } from './pages/pages/auth/guard/auth.guard';

const childrenRoutes: VexRoutes = [
  {
    path: '',
    redirectTo: 'dashboards/analytics',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboards/analytics',
    loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'apps',
    children: [
     
      {
        path: 'aio-table',
        loadChildren: () => import('./pages/apps/aio-table/aio-table.module').then(m => m.AioTableModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'pages',
    children: [
      
      {
        path: 'invoice',
        loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
      },
      {
        path: 'printinvoicebulk',
        loadChildren: () => import('./pages/pages/invoice/printinvoicebulk/printinvoicebulk.module').then(m => m.PrintInvoiceBulkModule)
      },
      {
        path: 'createinvoice',
        loadChildren: () => import('./pages/pages/invoice/generate-invoice/create-invoice/create-invoice.module').then(m => m.InvoiceModule)
      },
      {
        path: 'sage',
        loadChildren: () => import('./pages/pages/invoice/invoice-templates/invoice-template-sage/invoiceprint-sage.module').then(m => m.InvoicePrintSageModule)
      },
      {
        path: 'avatar',
        loadChildren: () => import('./pages/pages/invoice/invoice-templates/invoice-template-avatar/invoiceprint-avatar.module').then(m => m.InvoicePrintAvatarModule)
      },
      {
        path: 'motoluxzo',
        loadChildren: () => import('./pages/pages/invoice/invoice-templates/invoice-template-motoluxzo/invoiceprint-motoluxzo.module').then(m => m.InvoicePrintMotoluxzoModule)
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'configuration',
    children: [
        {
          path: 'printconfig',
          loadChildren: () => import('./pages/configuration/printconfig.module').then(m => m.PrintConfigModule),
          data: {
            toolbarShadowEnabled: true
          }
        },
        {
          path: 'webconfig',
          loadChildren: () => import('./pages/webconfig/webconfig.module').then(m => m.WebConfigModule),
          data: {
            toolbarShadowEnabled: true
          }
        }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'uploadfile',
    children: [
        {
          path: 'bulkupload',
          loadChildren: () => import('./pages/uploadfile/uploadfile.module').then(m => m.UploadFileModule),
          data: {
            toolbarShadowEnabled: true
          }
        }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'invoicehistory',
    children: [
        {
          path: 'report',
          loadChildren: () => import('./pages/pages/invoice/invoicehistory/invoicehistory.module').then(m => m.InvoicehistoryModule),
          data: {
            toolbarShadowEnabled: true
          }
        },
        {
          path: 'orders',
          loadChildren: () => import('./pages/facturationweb/facturationweb.module').then(m => m.FacturationWebModule),
          data: {
            toolbarShadowEnabled: true
          }
        },{
          path: 'backmarketorders',
          loadChildren: () => import('./pages/facturationbackmarket/facturationbackmarket.module').then(m => m.FacturationBackMarketModule),
          data: {
            toolbarShadowEnabled: true
          }
        }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
  }
];

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: '',
    component: LayoutComponent,
    children: childrenRoutes,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
