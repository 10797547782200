import { Injectable } from '@angular/core';
import { Item } from '../../../models/item';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Invoice } from 'src/models/invoiceVm';
import { CustomerAddress } from 'src/models/customerAddress';
import { GOrder } from 'src/models/gorder';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  items = new Array();
  
  activeInvoice = new BehaviorSubject<Invoice>(new Invoice("",0,[]));


  constructor(private http : HttpClient) { }
  

  setActiveInvoice(invoice : Invoice)
  {
    if(!invoice)
    {
      this.activeInvoice.next(new Invoice("",0,[]));
    }
    else
    {
      this.activeInvoice.next(invoice);
    }

    
  }
  getActiveInvoice()
  {
    return this.activeInvoice;
  }


  add( item : Item)
  {  
    this.items.push(item)
  }

  update(item : Item)
  {
    let itemindex = this.items.indexOf(item)
    if(itemindex != -1)
    this.items[itemindex] = item 
  }

  delete(itemindex : number)
  {
    this.items.splice(itemindex, 1)
  }

  get()
  {
    return this.items
  }
generateInvoice(invoiceobj : Invoice) : Observable<any>
{
  let user = JSON.parse(localStorage.getItem('currentUser'))
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':'Bearer ' +  user.token.token
    })}
    return  this.http.post(environment.endPointUri +'/api/invoice',invoiceobj, httpOptions );
}
deleteInvoice(invoiceref) : Observable<any>
{
  let user = JSON.parse(localStorage.getItem('currentUser'))
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':'Bearer ' +  user.token.token
    })}
    return  this.http.delete(environment.endPointUri +'/api/invoice/'+invoiceref, httpOptions );
}

getInvoices() : Observable<any>
{
  let user = JSON.parse(localStorage.getItem('currentUser'))
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':'Bearer ' +  user.token.token
    })}
    return  this.http.get(environment.endPointUri +'/api/invoice',httpOptions );
}

getInvoicesByDate(startingdate , endingdate) : Observable<any>
{
  let user = JSON.parse(localStorage.getItem('currentUser'))
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':'Bearer ' +  user.token.token
    })}
    return  this.http.post(environment.endPointUri +'/api/invoice/invoicestartenddate',{ "StartingDate" : startingdate, "EndingDate" : endingdate},httpOptions);
}

getInvoicesforExport(){
  
  let user = JSON.parse(localStorage.getItem('currentUser'))
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':'Bearer ' +  user.token.token
    })}
    return  this.http.post(environment.endPointUri +'/api/invoice/invoicestartenddate',httpOptions);
}

updateInvoiceAddress(invoiceref , customeraddress : CustomerAddress){

  let user = JSON.parse(localStorage.getItem('currentUser'))
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':'Bearer ' +  user.token.token
    })}
    return  this.http.post(environment.endPointUri +'/api/invoice/updateinvoice',{"InvoiceRefNumber" : invoiceref, "CustomerAddress" : customeraddress} ,httpOptions );     

}

//invoiceanalisys
getInvoicesAnalisys() : Observable<any>
{
  let user = JSON.parse(localStorage.getItem('currentUser'))
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':'Bearer ' +  user.token.token
    })}
    return  this.http.get(environment.endPointUri +'/api/invoice/invoiceanalisys',httpOptions );
}

//resentinvoice
getInvoicesResentlyCreated() : Observable<any>
{
  let user = JSON.parse(localStorage.getItem('currentUser'))
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':'Bearer ' +  user.token.token
    })}
    return  this.http.get(environment.endPointUri +'/api/invoice/resentinvoice',httpOptions );
}

uploadFile(data ) : Observable<any>
{
  let user = JSON.parse(localStorage.getItem('currentUser'))
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':'Bearer ' +  user.token.token
    }),
    
  }
  
    return this.http.post(environment.endPointUri+'/api/invoice/backmarketinvoice', {'Data' : data}, httpOptions)
}

addinvoicesWeb(data :GOrder[] ) : Observable<any>
{
  let user = JSON.parse(localStorage.getItem('currentUser'))
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':'Bearer ' +  user.token.token
    }),
    
  }
  
    return this.http.post(environment.endPointUri+'/api/invoice/webinvoice', data, httpOptions)
}

//backmarketinvoicecreate
addinvoicesbackMarket(data :GOrder[] ) : Observable<any>
{
  let user = JSON.parse(localStorage.getItem('currentUser'))
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':'Bearer ' +  user.token.token
    }),
    
  }
  
    return this.http.post(environment.endPointUri+'/api/invoice/backmarketinvoicecreate', data, httpOptions)
}
}
