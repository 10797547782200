import { Component, OnInit, HostListener, ElementRef, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, ControlValueAccessor } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { PrintingService } from 'src/app/services/printing/printing.service';
import { InvoiceService } from 'src/app/services/invoice/invoice.service';
import { HttpEventType } from '@angular/common/http';
import { BackMarketObject } from 'src/models/backmarketobject';

@Component({
  selector: 'vex-uploadfile',
  templateUrl: './uploadfile.component.html',
  styleUrls: ['./uploadfile.component.scss']
})
export class UploadfileComponent implements OnInit, ControlValueAccessor {
  
  loading = new EventEmitter<boolean>(false)
  
  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
  }


  fileData: File = null;
  fileupload: FormGroup;
  onChange: Function
  private file: File | null = null;
  progress
  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.file = file;
  }


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackbar: MatSnackBar,
    private invoiceservice: InvoiceService,
    private host: ElementRef<HTMLInputElement>,
    private printconfigService: PrintingService) { }

  ngOnInit() {

    this.fileupload = new FormGroup({
      svcfile: new FormControl(null, [Validators.required, requiredFileType('csv')])
    });
  }


  get filesvc() { return this.fileupload.get('svcfile'); }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  submit() {

        this.fileupload.reset()

        this.loading.emit(true)
        let reader: FileReader = new FileReader();
        reader.readAsText(this.file);
        reader.onload = (e) => {
           let csv: string = reader.result as string;
           let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        let headersRow = this.getHeaderArray(csvRecordsArray);
        let records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
        
         this.invoiceservice.uploadFile(records)
           .subscribe(event => {
            this.loading.emit(false)
            //  if (event.type === HttpEventType.UploadProgress) {
            //    this.progress = Math.round((100 * event.loaded) / event.total);
            //  }
     
            //  if (event.type === HttpEventType.Response) {
            //    console.log(event.body);
            //    this.filesvc.reset();
            //  }
     
           },
           error=>{
            this.loading.emit(false)
           });
     }
   
  }

  reset() {
    this.fileupload.reset()
  }

  excludeVat(obj : BackMarketObject  ) 
  {

    obj.tax = 0
    let _percent = (21 / 100)+1 ;
    obj['orderline_price'] = parseFloat(obj['orderline_price'].replace(',','.').replace(' ',''))
    let pric = obj['orderline_price'] * obj['quantity']
    
    let withouttaxprice = pric / _percent
    let fnprice = pric - withouttaxprice
    obj.tax = Math.round(fnprice * 100) / 100 
    obj.baseprice  = (pric  - fnprice)
    
    
  }
  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(';');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];
    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(';');
      if (curruntRecord.length == headerLength) {
        let csvRecord: BackMarketObject = new BackMarketObject();
        csvRecord.order_id = curruntRecord[0].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.payment_id =curruntRecord[1].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.psp_reference =curruntRecord[2].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.country=curruntRecord[3].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.order_state=curruntRecord[4].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.order_price=curruntRecord[5].trim().replace(/[&\/\\#+()$~%'":*?<>{}]/g, ''); 
        csvRecord.shipping_price=curruntRecord[6].trim().replace(/[&\/\\#+()$~%'":*?<>{}]/g, ''); 
        csvRecord.payment_method=curruntRecord[7].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.trust_pack_plus=curruntRecord[8].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.sku=curruntRecord[9].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.quantity=curruntRecord[10].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.warranty_delay=curruntRecord[11].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.rating=curruntRecord[12].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.product_id=curruntRecord[13].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.orderline_id=curruntRecord[14].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.orderline_state=curruntRecord[15].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.canceled_by=curruntRecord[16].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.orderline_title=curruntRecord[17].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.listing_state=curruntRecord[18].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.orderline_price=curruntRecord[19].trim().replace(/[&\/\\#+()$~%'":*?<>{}]/g, ''); 
        csvRecord.orderline_shipping_price=curruntRecord[20].trim().replace(/[&\/\\#+()$~%'":*?<>{}]/g, ''); 
        csvRecord.orderline_shipping_delay=curruntRecord[21].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.orderline_shipper=curruntRecord[22].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.return_reason=curruntRecord[23].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.tracking_number=curruntRecord[24].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.tracking_url=curruntRecord[25].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.shipper=curruntRecord[26].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.customer_email=curruntRecord[27].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.shipping_company=curruntRecord[28].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.shipping_first_name=curruntRecord[29].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.shipping_last_name=curruntRecord[30].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.shipping_street=curruntRecord[31].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.shipping_street2=curruntRecord[32].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.shipping_postal_code=curruntRecord[33].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.shipping_city=curruntRecord[34].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.shipping_country=curruntRecord[35].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.shipping_phone=curruntRecord[36].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.shipping_email=curruntRecord[37].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.sav_email=curruntRecord[38].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.billing_company=curruntRecord[39].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.billing_first_name=curruntRecord[40].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.billing_last_name=curruntRecord[41].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.billing_street=curruntRecord[42].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.billing_street2=curruntRecord[43].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.billing_postal_code=curruntRecord[44].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.billing_city=curruntRecord[45].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.billing_country=curruntRecord[46].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.billing_phone=curruntRecord[47].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.date_creation=curruntRecord[48].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.date_modification=curruntRecord[49].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.date_payment=curruntRecord[50].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.date_shipping=curruntRecord[51].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.currency=curruntRecord[52].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.orderline_fee=curruntRecord[53].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.bill_id=curruntRecord[54].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.imei=curruntRecord[55].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.model=curruntRecord[56].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.brand=curruntRecord[57].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.snowden=curruntRecord[58].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.category1=curruntRecord[59].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.category2=curruntRecord[60].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.category3=curruntRecord[61].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.sav=curruntRecord[62].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.customer_id_number=curruntRecord[63].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.FBBM=curruntRecord[64].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.refunded_bill=curruntRecord[65].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.refunded_date=curruntRecord[66].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.capture_date=curruntRecord[67].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''); 
        csvRecord.invoice_email=curruntRecord[68].trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
        this.excludeVat(csvRecord);
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }


}

export function requiredFileType(type: string) {
  return function (control: FormControl) {
    const file = control.value;
    if (file) {
      const extension = file.split('.')[1].toLowerCase();
      if (type.toLowerCase() !== extension.toLowerCase()) {
        return {
          requiredFileType: true
        };
      }
      return null;
    }
    return null;
  };
}

export function toFormData<T>(file ) {
  let formData: FormData = new FormData();
formData.append('file',file);
return formData
}