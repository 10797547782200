import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public token: string;
 
  constructor(private http: HttpClient,private router: Router) {
      // set token if saved in local storage
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.token = currentUser && currentUser.token;
  }

  login(username: string, password: string): Observable<any> {

    let body = JSON.stringify({ email: username, password: password })
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })}
      return  this.http.post<Observable<any[]>>(environment.endPointUri +'/api/authenticate',body, httpOptions );
  }

  register(email, password)
  {
    let body = JSON.stringify({ email: email, password: password })
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })}

      return  this.http.post<Observable<any[]>>(environment.endPointUri +'/api/authenticate/register',body, httpOptions );
  }
  logout(): void {
      // clear token remove user from local storage to log user out
      this.token = null;
      localStorage.removeItem('currentUser');
      this.router.navigate(['/login']);
  }
  getAppToken()
  {
    let user = JSON.parse(localStorage.getItem('currentUser'))
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':'Bearer ' +  user.token.token
      })}
      return  this.http.get(environment.endPointUri +'/api/apptoken/gettoken', httpOptions );
  }

  
}


