import { CustomerAddress } from './customerAddress'


export class Invoice{

    private CustomerAddress
    private ShopAddress

    customerAddress : CustomerAddress

    constructor(
        refNumber :string , 
        invoiceNumber : number,
        items : Item[] )
    {
        this.refNumber = refNumber
        this.invoiceNumber= invoiceNumber
        this.items = items
    }

     setcustomerAddress(customerAddress : CustomerAddress)
    {
        this.CustomerAddress = customerAddress
    }

    getcustomerAddress() : CustomerAddress
    {
        return this.CustomerAddress
    }

    getshopAddress()
    {
        return this.ShopAddress
    }

     shopAddress(shopAddress : ShopAddress)
    {
        this.ShopAddress = shopAddress
    }
    refNumber : string
    invoiceNumber : number
    invoicedate : string
    invoiceDateTime : string
    invoiceId : number 
    invoiceType : string
    customerAddressId : string
    id :string
    items : Item []
    

}

export class Item{
    descripction : string
    quantity : number
    price : number
    taxprice : number
    
    tax : number
    base : number
    taxRe : number
    taxReTotal : number
    re : boolean
    isTaxExcluded : boolean | false

   
}

export class ShopAddress{

    userId : string
    shopName : string
    address : string
    cif : string
    shopTelefone : string

}