export  class BackMarketObject {

order_id;
payment_id;
psp_reference;
country;
order_state;
order_price;
shipping_price;
payment_method;
trust_pack_plus;
sku;quantity;
warranty_delay;
rating;
product_id;
orderline_id;
orderline_state;
canceled_by;
orderline_title;
listing_state;
orderline_price;
orderline_shipping_price;
orderline_shipping_delay;
orderline_shipper;
return_reason;
tracking_number;
tracking_url;
shipper;
customer_email;
shipping_company;
shipping_first_name;
shipping_last_name;
shipping_street;
shipping_street2;
shipping_postal_code;
shipping_city;
shipping_country;
shipping_phone;
shipping_email;
sav_email;
billing_company;
billing_first_name;
billing_last_name;
billing_street;
billing_street2;
billing_postal_code;
billing_city;
billing_country;
billing_phone;
date_creation;
date_modification;
date_payment;
date_shipping;
currency;
orderline_fee;
bill_id;
imei;
model;
brand;
snowden;
category1;
category2;
category3;
sav;
customer_id_number;
FBBM;
refunded_bill;
refunded_date;
capture_date;
invoice_email

baseprice  
tax

}